// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.slide-in {
  animation: slideInLeft 0.5s ease forwards;
}

.fade-out {
  animation: fadeOut 0.5s ease forwards;
}

form.form-add-user {
  max-height: 80vh;
  overflow: scroll;
  padding: 0 .5rem 3rem;
}

.ranking-item {
  max-width: 600px;
}

.medalla,
.puesto {
  position: absolute;
  right: -17px;
  bottom: -5px;
}

.puesto {
  right: -12px;
  bottom: 0;
  background-color: #f1c40f;
  color: #fff;
  padding: 0 5px;
  border-radius: 0 0 5px 0;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE;IACE,4BAA4B;IAC5B,UAAU;EACZ;EACA;IACE,wBAAwB;IACxB,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,yBAAyB;EACzB,WAAW;EACX,cAAc;EACd,wBAAwB;AAC1B","sourcesContent":[".App * {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n@keyframes slideInLeft {\n  0% {\n    transform: translateX(-100%);\n    opacity: 0;\n  }\n  100% {\n    transform: translateX(0);\n    opacity: 1;\n  }\n}\n\n@keyframes fadeOut {\n  0% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n\n.slide-in {\n  animation: slideInLeft 0.5s ease forwards;\n}\n\n.fade-out {\n  animation: fadeOut 0.5s ease forwards;\n}\n\nform.form-add-user {\n  max-height: 80vh;\n  overflow: scroll;\n  padding: 0 .5rem 3rem;\n}\n\n.ranking-item {\n  max-width: 600px;\n}\n\n.medalla,\n.puesto {\n  position: absolute;\n  right: -17px;\n  bottom: -5px;\n}\n\n.puesto {\n  right: -12px;\n  bottom: 0;\n  background-color: #f1c40f;\n  color: #fff;\n  padding: 0 5px;\n  border-radius: 0 0 5px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
